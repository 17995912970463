import { template as template_fb05b08c036e4bcfbc8dfdc424344b6f } from "@ember/template-compiler";
const WelcomeHeader = template_fb05b08c036e4bcfbc8dfdc424344b6f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
