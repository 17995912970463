import { template as template_06bf54dcb5b4476fbe839f6dbe3d6c0f } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class FKControlCustom extends Component {
    static controlType = "custom";
    static{
        template_06bf54dcb5b4476fbe839f6dbe3d6c0f(`
    <div class="form-kit__control-custom">
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
