import { template as template_4b349645a06c4629a1f33d65238e8f19 } from "@ember/template-compiler";
const FKLabel = template_4b349645a06c4629a1f33d65238e8f19(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
