import { template as template_e3103315372a4841beb9e7a5ba64e5e7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e3103315372a4841beb9e7a5ba64e5e7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
