import { template as template_2a09de9cb24a47c2a38d1285291012c7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_2a09de9cb24a47c2a38d1285291012c7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
