import { template as template_ef01a6863e13471680d61d2c10e9109f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_ef01a6863e13471680d61d2c10e9109f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
