import { template as template_20d270d2900f43c79ff1c06df4b76c8c } from "@ember/template-compiler";
const SidebarSectionMessage = template_20d270d2900f43c79ff1c06df4b76c8c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
